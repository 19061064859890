



























































































































































import { Component, Vue } from "vue-property-decorator";
import {
  CustomerBillAddressMappingDto,
  CustomerDto,
  CustomerReceivingAddressMappingDto,
} from "@/api/appService";
import api from "@/api";

@Component({
  name: "CustomDetail",
  components: {},
})
export default class CustomDetail extends Vue {
  dataId?: number;
  detail: CustomerDto = {};
  orderInvoiceList: CustomerBillAddressMappingDto[] | undefined = [];
  receivingAddressList: CustomerReceivingAddressMappingDto[] | undefined = [];
  loading = true;

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchDetail();
      this.loading = false;
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.customer.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
      this.orderInvoiceList = res?.billAddressList;
      this.receivingAddressList = res?.receivingAddressList;
    });
  }
}
